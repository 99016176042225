.form-page {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    font-family: Arial, sans-serif;
}

.form-header {
    font-weight: lighter;
    /*font-family: 'Courier New', Courier, monospace; !* Change font to resemble a typewriter *!*/
    white-space: pre-wrap; /* Preserve whitespace to keep the text formatting */
    overflow: hidden; /* Hide overflowing text to create the typing effect */
}

.winner-message {
    text-align: left;
    margin-top: 30px;
    font-size: 16px;
    line-height: 1.5;
    white-space: pre-wrap; /* Ensures that line breaks are respected */
}



.form-step {
    margin-bottom: 30px;
}

.form-step h2 {
    font-size: 22px;
    margin-bottom: 20px;
    font-weight: lighter;
}

.form-input {
    width: 70%;
    height: 40px;
    padding: 10px;
    font-size: 18px;
    border: 2px solid #ced4da;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 4px;
}

.navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.nav-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.back-button {
    background-color: #f1f1f1;
}

.next-button {
    background-color: #007bff;
    color: white;
}

.submit-button {
    background-color: #28a745;
    color: white;
}

.nav-button:hover {
    opacity: 0.8;
}

/* New styles for order confirmation */
.order-confirmation {
    text-align: center;
    font-family: Arial, sans-serif;
    padding: 20px;
}

.order-confirmation h2 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: lighter;
}

.order-confirmation p {
    font-size: 18px;
}
